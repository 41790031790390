import { Layout, Menu, Input, Button, Checkbox } from 'element-react';
import { useTranslation } from 'react-i18next';
import filterSearchStore from '../store/filter-search';
import {useEffect, useRef, useState} from 'react';
const LeftMenuFilter = (props) =>{
    const {t} = useTranslation();
    //const {categories, setCategories}  = categoryStore();
    const {filterInfo, setFilterInfo, clearFilterInfo, 
        clearSelectedCategories, selectedCategories, setSelectedCategories
    } = filterSearchStore();
    //const inputEl = useRef(null);
    //const [sliderValues, setSliderValues] = useState([10, 50000]);
    const [minPrice, setMinPrice] = useState();
    const [maxPrice, setMaxPrice] = useState();
    const [localFilterOptions, setLocalFilterOptions] = useState(
        {
            customAllowed: false,
            warranty: false,
            minBuy: false,
        }
    );
    const onOpen =(menu)=> {
        console.log('open :: ' + menu);
    }
    const onSelect =(index)=> {
        console.log('selected :: ' + index);
    }

    useEffect(() => {
        console.log('Left menu filter', {filterInfo});
    },[
        filterInfo.categories
    ])

    const handlePriceFilter = () =>{
        setFilterInfo(filterInfo.pageSize, minPrice === '' ? 0 : minPrice , maxPrice === '' ? 10000000 : maxPrice, 
            filterInfo.customAllowed, filterInfo.warranty, filterInfo.minBuy);
        console.log(filterInfo);
        //setSliderValues([filterInfo.minPrice, filterInfo.maxPrice]);
    }
    useEffect(() => {
        console.log('Left menu filter', {filterInfo});
        setMinPrice(filterInfo.minPrice === 0 ? '' : filterInfo.minPrice);
        setMaxPrice(filterInfo.maxPrice === 10000000 ? '' : filterInfo.maxPrice);
        setLocalFilterOptions(
            {
                customAllowed: filterInfo.customAllowed,
                warranty: filterInfo.warranty,
                minBuy: filterInfo.minBuy
            }
        );
    },[])

   const handleFilterCustomAllowedChange = (v) =>{
       console.log(v);
       setLocalFilterOptions({...localFilterOptions, customAllowed : v })
       setFilterInfo(filterInfo.pageSize, filterInfo.minPrice , filterInfo.maxPrice,  v, filterInfo.warranty, filterInfo.minBuy);
   }

    const handleFilterWarrantyChange = (v) =>{
        console.log(v);
        setLocalFilterOptions({...localFilterOptions, warranty : v })
        setFilterInfo(filterInfo.pageSize, filterInfo.minPrice , filterInfo.maxPrice,  filterInfo.customAllowed, v, filterInfo.minBuy);
    }
    const handleFilterMinBuyChange = (v) =>{
        console.log(v);
        setLocalFilterOptions({...localFilterOptions, minBuy : v })
        setFilterInfo(filterInfo.pageSize, filterInfo.minPrice , filterInfo.maxPrice,  filterInfo.customAllowed, filterInfo.warranty, v );
    }

    return(
        <Layout.Row className="tac filtered-left-menu">
            <Layout.Col span={24}>
                <div className="sidenav">
                    <Menu defaultActive="1" className="el-menu-vertical-demo" onOpen={onOpen.bind(this)} onSelect={(index) =>{onSelect(index)}}>
                            <div>
                                &nbsp;
                            </div>
                            <div className="left-menu-filter">
                            <Layout.Row type="flex" span="24">
                                <Layout.Col span="" offset="1" xs="24" sm="22" md="22" lg="22">
                                    <h3>{t('search.left-filter.title')}</h3>
                                </Layout.Col>
                            </Layout.Row>    
                            <>
                                {
                                    filterInfo.categories?.length > 0 ? 
                                    <>
                                        <Layout.Row type="flex" span="24">
                                            <Layout.Col span="" offset="1" xs="24" sm="22" md="22" lg="22">
                                                <h4>Filter Category</h4>
                                            </Layout.Col>
                                        </Layout.Row> 
                                        <>
                                            {
                                                filterInfo.categories?.map((category, index) => {
                                                    return (
                                                        <Layout.Row key={index} type="flex" span="24">
                                                            <Layout.Col span="" className="bottom-spacer-10" offset="1" xs="24" sm="22" md="22" lg="22">
                                                                <Checkbox
                                                                    id={category.id}
                                                                    checked={selectedCategories.includes(category)}
                                                                    onChange={(v) => {
                                                                        if(v) {
                                                                            setSelectedCategories([...selectedCategories, category]);
                                                                        } else {
                                                                            setSelectedCategories(selectedCategories.filter((c) => c !== category));
                                                                        }
                                                                    }}
                                                                >
                                                                    {category}
                                                                </Checkbox>
                                                            </Layout.Col>
                                                        </Layout.Row>    
                                                    )
                                                })
                                            }
                                        </>
                                    </>
                                    
                                    :
                                    <></>
                                }
                                
                            </>
                            <Layout.Row type="flex" span="24">
                                <Layout.Col span="" offset="1" xs="24" sm="22" md="22" lg="22">
                                    <h4>{t('search.left-filter.sub-title1')}</h4>
                                </Layout.Col>
                            </Layout.Row>    
                            <Layout.Row type="flex" span="24">
                                <Layout.Col span="" className="bottom-spacer-10" offset="1" xs="24" sm="22" md="22" lg="22">
                                    <Checkbox
                                        checked={localFilterOptions.customAllowed}
                                        onChange={(v) => {handleFilterCustomAllowedChange(v)}}
                                    >
                                    
                                        {t('search.left-filter.custom')}
                                    </Checkbox>
                                </Layout.Col>
                            </Layout.Row>    
                            {
                                /*
                                <Layout.Row type="flex" span="24">
                                    <Layout.Col span="" className="bottom-spacer-10" offset="1" xs="24" sm="22" md="22" lg="22">
                                        <Checkbox>
                                            {t('search.left-filter.ship')}
                                        </Checkbox>
                                    </Layout.Col>
                                </Layout.Row>    
                                */
                            }
                            <Layout.Row type="flex" span="24">
                                <Layout.Col span="" className="bottom-spacer-10" offset="1" xs="24" sm="22" md="22" lg="22">
                                    <Checkbox
                                        checked={localFilterOptions.warranty}
                                        onChange={(v) => {handleFilterWarrantyChange(v)}}
                                    >
                                        {t('search.left-filter.warranty')}
                                    </Checkbox>
                                </Layout.Col>
                            </Layout.Row>    
                            <Layout.Row type="flex" span="24">
                                <Layout.Col span="" offset="1" xs="24" sm="22" md="22" lg="22">
                                    <Checkbox
                                        checked={localFilterOptions.minBuy}
                                        onChange={(v) => {handleFilterMinBuyChange(v)}}
                                    >
                                        {t('search.left-filter.min-buy')}
                                    </Checkbox>
                                </Layout.Col>
                            </Layout.Row>    
                            <Layout.Row type="flex" span="24">
                                <Layout.Col span="" offset="1" xs="24" sm="22" md="22" lg="22">
                                    <h4>{t('search.left-filter.sub-title2')}</h4>
                                </Layout.Col>
                            </Layout.Row>    
                            <Layout.Row type="flex" span="24">
                                <Layout.Col span="" offset="1" xs="24" sm="22" md="22" lg="7">
                                        <Input value={minPrice} onChange={v=>{setMinPrice(v)}} style={{fontSize: '10px'}}/>
                                </Layout.Col>
                                <Layout.Col span=""
                                    style={{top: '8px', position:'relative', left: '5px'}}
                                    xs="1" sm="1" md="1" lg="1">
                                    -
                                </Layout.Col>
                                <Layout.Col span="1" offset="1" xs="24" sm="22" md="22" lg="7">
                                    <Input value={maxPrice} onChange={v=>{setMaxPrice(v)}} style={{fontSize: '10px'}}/>
                                </Layout.Col>
                                <Layout.Col  span="1" xs="1" sm="1" md="1" lg="1">
                                    
                                </Layout.Col>
                                <Layout.Col tag="form-button-inverse" className="left-filter" lg="6">
                                        <Button onClick={handlePriceFilter} style={{width: '50px', height: '30px', marginTop: '3px', padding: '0px'}}  type="primary">Ok</Button>
                                </Layout.Col>
                            </Layout.Row>
                            </div>
                    </Menu>
                </div>
            </Layout.Col>
        </Layout.Row>
    )
}

export default LeftMenuFilter;