import create from 'zustand'
import { persist } from 'zustand/middleware'
//@ts-ignore
const [filterSearchStore] = create(persist(set => ({
  filterInfo: { pageSize : 25, minPrice: 0, maxPrice : 10000000, customAllowed: false, warranty: false, minBuy: false, categories: []},
  setFilterInfo: (pageSize, minPrice, maxPrice, customAllowed, warranty, minBuy, categories) => {
    set({ filterInfo: {pageSize, minPrice, maxPrice , customAllowed, warranty, minBuy, categories}});
  },
  selectedCategories: [],
  setSelectedCategories: (categories) => set({selectedCategories : categories}),
  clearSelectedCategories: () => set({selectedCategories : []}),
  clearFilterInfo : () => set({filterInfo : { pageSize : 25, minPrice: 0, maxPrice : 10000000, customAllowed: false, warranty: false, minBuy: false, categories: []}}),
})))

export default filterSearchStore;