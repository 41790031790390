import { useParams } from "react-router-dom";
import { Layout, Loading} from 'element-react';
import {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {getProduct, listCompanys, listUsers} from '../graphql/queries';
import {updateProduct} from '../graphql/mutations'
import {API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql";
import ProductView from '../components/ProductView';
import PagerQuery from '../utils/PagerQuery';

function ProductDetail(){
    let { productid } = useParams();
    const [loading, setLoading] = useState(false);
    const [mainPhoto, setMainPhoto] = useState(false);
    const [productPage, setProductPage] = useState(null);
    const [companyPage, setCompanyPage] = useState(null);
    const [userOwner, setUserOwner] = useState(null);

    useEffect(() => {
        console.log('Getting product with id :: ' + productid);
        window.scrollTo(0, 0);
        getProductFromDB();
    }, [productid]);

    const handleChangeMainPhoto = (key)=>{
        setMainPhoto(key);
    }

    const getProductFromDB = async () =>{
        setLoading(true);
        try{
            let input = {
                id: productid
            }
            const result  = await API.graphql({
                query: getProduct,
                variables: input,
                authMode: GRAPHQL_AUTH_MODE.AWS_IAM
              });
            console.log({result})
            //@ts-ignore
            setProductPage(result.data.getProduct);

            
            /* UPDATE VIEWS!! */
            console.log('Update views');
            input = {
                id: productid,
                views: ((result.data.getProduct.views != null ? result.data.getProduct.views : 0)  + 1)
            }
            const resultViews  = await API.graphql({
                query: updateProduct,
                variables: {input},
                authMode: GRAPHQL_AUTH_MODE.AWS_IAM
              });
            console.log({resultViews})
            
            
            /*const resultPage  = await API.graphql({
                query: listCompanys,
                variables: { filter: { owner: {eq: result.data.getProduct.owner } } },
                authMode: GRAPHQL_AUTH_MODE.AWS_IAM
              });*/
            const inputListCompany = { filter: { owner: {eq: result.data.getProduct.owner } } }
            
            const pagerQueryCompany = new PagerQuery(listCompanys, inputListCompany, Object.keys({ listCompanys }));
            const resultPage = await pagerQueryCompany.searchPublicData();
            console.log({resultPage})
            //@ts-ignore
            setCompanyPage(resultPage.data.listCompanys.items[0]);

            /*const resultUserPage  = await API.graphql({
                query: listUsers,
                variables: { filter: { cognito_id: {eq: resultPage.data.listCompanys.items[0].owner } } }
            });*/
            const inputListUsers = { filter: { cognito_id: {eq: resultPage.data.listCompanys.items[0].owner } } }
            const pagerQuery = new PagerQuery(listUsers, inputListUsers, Object.keys({ listUsers }));
            const resultUserPage = await pagerQuery.searchPrivateData();
            console.log({resultUserPage})
            setUserOwner(resultUserPage.data.listUsers.items[0]);

            setLoading(false);
        }catch(e){
            setLoading(false);
            console.error(e);
        }
    }

    return(
        <div>
            {
                loading && <Loading fullscreen={loading} />
            }
            <div>
                <Layout.Row gutter="24">
                    <Layout.Col span="24">
                        {
                            productPage && productPage.category 
                            ?
                                <p className="route-sub-title">{productPage.category.category_name} {' > '} {productPage.category.name} </p>
                            :
                            ''
                        }
                        
                    </Layout.Col>
                </Layout.Row>
                

                <ProductView setLoading={setLoading} companyPage={companyPage} mainPhoto={mainPhoto} handleChangeMainPhoto={handleChangeMainPhoto} productPage={productPage} userOwner={userOwner} />
                
                <Layout.Row type="flex">
                    <Layout.Col span="24" offset="1" xs="20" sm="20" lg="20">
                        &nbsp;
                    </Layout.Col>
                </Layout.Row>
                
            </div>
        </div>
    );
}

export default ProductDetail;
