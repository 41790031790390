import React, { useState, useEffect } from 'react';
import { Dialog } from 'element-react';

export default function VideoTwo ({ language, visible, setVisible }) {
    const [iframeSrc, setIframeSrc] = useState("https://www.youtube.com/embed/VzscAAI5RCQ?si=S9ct3xnO1BgF-soS");

    // Function to stop the video by resetting the iframe src
    const stopVideo = () => {
        setIframeSrc(''); // Remove the src to stop the video
        setTimeout(() => {
            setIframeSrc("https://www.youtube.com/embed/VzscAAI5RCQ?si=S9ct3xnO1BgF-soS"); // Reset src to original
        }, 10); // Delay to ensure the iframe reloads
    }

    return (
        <Dialog 
            visible={visible}
            onCancel={() => {
                stopVideo(); // Stop the video when closing the dialog
                setVisible(false); // Update visibility state
            }}
            lockScroll={false}
        >
            <Dialog.Body className='dialog-body-footer'>
                <iframe 
                    width="100%" 
                    height="515" 
                    src={iframeSrc} 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen
                ></iframe>
            </Dialog.Body>
        </Dialog>
    );
}
