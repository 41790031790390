import React, {useState, useRef, useEffect} from 'react';
import 'element-ui/lib/theme-chalk/display.css';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from "react-router-dom";
import prevNavStore from '../store/previousNav';
import {useLastLocation} from 'react-router-last-location';

import {API, Auth} from 'aws-amplify';
import {createUser} from '../customQueries/Queries';
import {GRAPHQL_AUTH_MODE} from "@aws-amplify/api-graphql"

import {Layout, Button, Form, Input, Select, Switch, Loading, MessageBox, Checkbox} from 'element-react';
import {loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha} from 'react-simple-captcha';
import Popup from 'reactjs-popup';

import {countryOptions, contactMethodsOptions} from '../utils/Catalogs';
import registerRules from '../utils/RegisterRules';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from '../pages/TermsOfUse'
import RegisterBanner from "../assets/imgs/img_register_bullets.png";
import RegisterBannerEsp from "../assets/imgs/img_register_bullets_esp.png";


import logInDB from '../graphql/logLambdaInvokes';
import * as LogType from '../utils/LogType';
import ZohoWidget from '../components/ZohoWidget';


import '../../node_modules/reactjs-popup/dist/index.css';

function Register() {
    /*
    Analytics.record({ 
        name: 'registerVisit', 
        data: { 
        // The data blob to put into the record'
            d : 'DATA'
        },
    streamName: 'stream-kinesis-markeplace' }, 'AWSKinesisFirehose');
    */
    const {t} = useTranslation();
    const form = useRef(null);
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [phoneCode, setPhoneCode] = useState("-");
    const [isPrivacyPolicyVisible, setIsPrivacyPolicyVisible] = useState(false);
    const refPassword = useRef(null);
    const refUsername = useRef(null);
    const {path, setPath, clearPath} = prevNavStore();
    const lastLocation = useLastLocation();
    const [formInputs, setFormInputs] = useState({
        seller: '',
        name: '',
        lastName: '',
        email: '',
        company: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        captcha: '',
        hasCompany: false,
        termsAndConditions: '',
        country: '',
        contactType: '',
        refCode : ''
    })
    const [rules, setRules] = useState();
    const [country] = useState(countryOptions);
    const [contactType] = useState(contactMethodsOptions);
    const language = t('language')

    useEffect(() => {
        //loadCaptchaEnginge(6);
        setRules(registerRules(t, refPassword, true));
        if (lastLocation == null) clearPath(); else setPath(lastLocation.pathname);
        setTimeout(() => {
            console.log('path :: ' + path);
        }, 1000);

    }, [t])

    const changeRules = (v) => {
        console.log('changeRules :: ' + v);
        if(v == 'true'){
            setRules(registerRules(t, refPassword, true));
        }else{
            setRules(registerRules(t, refPassword, false));
        }
    }

    const handleObSubmit = () => {
        console.log('Submit');
        console.log('Terms and conditions value :: ' + formInputs.phoneNumber);
        window.scrollTo(0, 0);
        //if (validateCaptcha(formInputs.captcha) === false) {
        if (false) {
            console.log('return');
            setFormInputs({...formInputs, captcha: ''});
            form.current.validate();
            printFieldsWithError(form);
            return;
        } else {
            console.log('else');
            //form.current.validate();
            form.current.validate((valid) => {
                console.log('validator :: ' + valid);
                if (valid) {
                    signUp();
                } else {
                    console.log('error submit!!');
                    printFieldsWithError(form);
                    return false;
                }
            });
        }
    }

    const [name, setName] = useState(RegisterBannerEsp);

    const changeName = () => {
      let value = name;
  
      if (value === RegisterBannerEsp) {
        setName(RegisterBanner);
      } else {
        setName(RegisterBannerEsp);
      }
    };

    const printFieldsWithError = (form) => {
        let c = form.current;
        console.log({c});
        let fieldsWithError = [];
        for (let i = 0; i < c.state.fields.length; i++) {
            if (c.state.fields[i].state.valid === false) {
                if (c.state.fields[i].props.label != undefined) {
                    fieldsWithError.push(c.state.fields[i].props.label);
                } else {
                    fieldsWithError.push('Your approval of the Terms of Use and Privacy Policy is required to continue.');
                }

            }
        }
        if (fieldsWithError.length > 0) {
            let msg = '';
            msg = fieldsWithError.join(' \n');
            console.log('fieldsWithError.length :: ' + fieldsWithError.length);
            console.log('fieldsWithError :: ' + msg);
            MessageBox.alert(msg, 'Review Fields with Error: ', {type: 'error'});
        }
    }

    const signUp = async () => {
        try {
            setLoading(true);
            const {user} = await Auth.signUp({
                username: (formInputs.email + "").trim().toLowerCase(), password: formInputs.password, attributes: {
                    email: (formInputs.email + "").trim().toLowerCase(), phone_number: phoneCode + formInputs.phoneNumber
                }
            });
            console.log(user);
            const input = {
                company_name: formInputs.company,
                first_name: formInputs.name,
                last_name: formInputs.lastName,
                user_type: formInputs.seller == 'true' ? "S" : "B", //TODO definir dato para Vendedor y comprador
                phone_number: phoneCode + formInputs.phoneNumber,
                username: (formInputs.email + "").trim().toLowerCase(),
                email: (formInputs.email + "").trim().toLowerCase(),
                country: formInputs.country,
                contact_type: formInputs.contactType,
                address_zip: "00000",
                cognito_id: "123",//user.pool.clientId
                ref_code: formInputs.refCode

            };
            console.log({input});
            const result = await API.graphql({
                query: createUser, variables: {input}, authMode: GRAPHQL_AUTH_MODE.AWS_IAM
            });

            console.log("Created user", result);
            setLoading(false);
            history.push("/register-confirm/" + (formInputs.email + "").trim().toLowerCase() + "/" + (formInputs.seller == 'true' ? "S" : "B"));


        } catch (error) {
            console.log('error signing up:', error);
            setLoading(false);
            let msg = t('register-form.error-1');
            if (error.code === "UsernameExistsException") {
                msg = t('register-form.error-2');
            }
            //loadCaptchaEnginge(6);
            MessageBox.alert(msg, 'Error', {type: 'error'});
        }
    }

    const renderCountries = country.options.map(el => <Select.Option key={el.value} value={el.value} label={el.label}/>)
    const renderContactTypes = contactType.options.map(el => <Select.Option key={el.value} value={el.value} label={el.label}/>)
    const updatePhoneCode = (v) => country.options.forEach(function (item) {
        if (item.value == v) setPhoneCode(item.phoneCode)
    })

    const showPrivacyPolicy = () => {
        setIsPrivacyPolicyVisible(true)
    }

    //TODO: PASSWORD RULES
    function showPasswordRules() {
        MessageBox.alert(t("general.password-rules"), t("general.password-rules-title"))
    }

    function focusEmail(v){
        console.log("focusEmail");
        console.log(v.target.value);
        logInDB({ message: v.target.value, 
            type: LogType.REGISTER_EMAIL_FOCUS_TYPE, 
            user: v.target.value
        });
    }

    function blurEmail(v){
        console.log("blurEmail");
        console.log(v.target.value);
        logInDB({ message: v.target.value, 
            type: LogType.REGISTER_EMAIL_BLUR_TYPE, 
            user: v.target.value
        });
    }

    

    if (loading) return <Loading loading={loading} fullscreen={true}/>
    return (<div>
        <ZohoWidget/>
        <Layout.Row className='registration_custom'>
        <Layout.Col xs="24" sm="24" md="12" lg="12" className='header01'>
        <Layout.Row gutter="10" type="flex" className="first-title-row">
            <Layout.Col span="24">
                <h2 className="form-title">{t('register-form.title')}</h2>
                <label className="el-form-item__label">{t('general.required-fields-label-1')} 
                <span className="red-dash">*</span> {t('general.required-fields-label-2')}</label>
            </Layout.Col>
        </Layout.Row>
        <Layout.Row>
            <Form ref={form} model={formInputs} className="en-US" rules={rules} label-position="top">
                <Layout.Row>
                    <Layout.Col xs="24" sm="24" md="11" lg="11">
                        <Form.Item label={t('Country (País)')} prop="country"><br/>
                            <Select filterable={false} value={formInputs.country} onChange={(v) => {
                                console.log({v});
                                setFormInputs({...formInputs, country: v});
                                updatePhoneCode(v);
                            }} placeholder={t('register-form.country-placeholder')}>
                                {renderCountries}
                            </Select>
                        </Form.Item>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="24" md="11" lg="11" className='pl-1'>
                        <Form.Item label={t('Account profile (Tipo de cuenta)')} prop="seller"><br/>                        
                            <Select filterable={false} value={formInputs.seller} onChange={(v) => {
                                console.log({v});
                                setFormInputs({...formInputs, seller: v});
                                changeName ();
                                changeRules(v);
                            }} placeholder={'Select an option'}>
                                <Select.Option key="B" value={'false'} label={'I want to buy products'}></Select.Option>
                                <Select.Option key="S" value={'true'} label={'I want to sell products'}></Select.Option>
                            </Select>
                        
                        </Form.Item>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row>
                    <Layout.Col xs="24" sm="24" md="12" lg="11">
                        <Form.Item label={t('Name (Nombre)')} prop="name">
                            <Input value={formInputs.name} onChange={(v) => {
                                setFormInputs({...formInputs, name: v})
                            }}></Input>
                        </Form.Item>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="24" md="11" lg="11" className='pl-1'>
                        <Form.Item label={t('Last name (Apellido)')} prop="lastName">
                            <Input value={formInputs.lastName} onChange={(v) => {
                                setFormInputs({...formInputs, lastName: v})
                            }}></Input>
                        </Form.Item>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row>             
                    <Layout.Col xs="24" sm="24" md="11" lg="11">
                        <Form.Item label={t('Phone Number (Teléfono)')} prop="phoneNumber">
                            <Input value={formInputs.phoneNumber} onChange={(v) => {
                                setFormInputs({...formInputs, phoneNumber: v.replace(/\D/g, '').substring(0, 10)})
                            }} prepend={phoneCode}></Input>
                        </Form.Item>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="24" md="11" lg="11" className='pl-1'>
                        <Form.Item label={t('Business Name (Tu Empresa)')} prop="company">
                            <Input value={formInputs.company} onChange={(v) => {
                                setFormInputs({...formInputs, company: v})
                            }}></Input>
                        </Form.Item>
                    </Layout.Col>
                </Layout.Row>
                <Layout.Row>
                <Layout.Col xs="24" sm="24" md="12" lg="11">
                        <Form.Item label={t('Business Email Address (Email de Empresa)')} prop="email">
                            <Input ref={refUsername} onFocus={(v)=>{focusEmail(v)}} onBlur={(v)=>{blurEmail(v)}} value={formInputs.email} onChange={(v) => {
                                setFormInputs({...formInputs, email: v})
                            }} placeholder={t('This will be your user name / será tu usuario')}/>
                        </Form.Item>
                </Layout.Col>
                <Layout.Col xs="24" sm="24" md="11" lg="11" className='pl-1'>
                        <Form.Item label={t('User Name (Nombre de usuario)')}><br/>
                            <label className="el-form-item__label email_label_register">{formInputs.email}</label>
                        </Form.Item>
                    </Layout.Col>
                    
                </Layout.Row>

                <Layout.Row>
                    
                </Layout.Row>
                <Layout.Row>
                    <Layout.Col xs="24" sm="24" md="11" lg="11">
                        <Form.Item label={t('Password (Contraseña)')} prop="password">
                            <Input type="password" icon="information" onIconClick={showPasswordRules}
                                   ref={refPassword} value={formInputs.password} onChange={(v) => {
                                setFormInputs({...formInputs, password: v})
                            }}></Input>
                        </Form.Item>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="24" md="11" lg="11" className='pl-1'>
                        <Form.Item label={t('Confirm password (Repite contraseña)')} prop="confirmPassword">
                            <Input type="password" value={formInputs.confirmPassword} onChange={(v) => {
                                setFormInputs({...formInputs, confirmPassword: v})
                            }}></Input>
                        </Form.Item>
                    </Layout.Col>
                </Layout.Row>

                <Layout.Row>
                    <Layout.Col xs="24" sm="24" md="11" lg="11">
                        <Form.Item label={'Preferred Contact Method'} prop="contactType">
                            <Select filterable={false} value={formInputs.contactType} onChange={(v) => {
                                    setFormInputs({...formInputs, contactType: v});
                                
                                }} placeholder={'Choose your preferred contact method'}>
                                {renderContactTypes}
                            </Select>
                        </Form.Item>
                    </Layout.Col>
                    <Layout.Col xs="24" sm="24" md="11" lg="11" className='pl-1'>
                        <Form.Item label={t('Referral code (Código de referencia)')} prop="refCode">
                            <Input maxLength={40} value={formInputs.refCode} onChange={(v) => {
                                setFormInputs({...formInputs, refCode: v})
                            }}></Input>
                        </Form.Item>
                    </Layout.Col>
                </Layout.Row>

                
                {
                    /*
                    <Layout.Row>
                    <Layout.Col xs="24" sm="24" md="11" lg="11">
                        <Form.Item>
                            <h2 className="form-subtitle">Captcha</h2>
                            <LoadCanvasTemplate/>
                        </Form.Item>
                    </Layout.Col>
                </Layout.Row>

                <Layout.Row>
                    <Layout.Col xs="24" sm="24" md="11" lg="13">
                        <Form.Item prop="captcha" label={t('Enter the captcha text (Ingresa el captcha)')}>
                            <Input value={formInputs.captcha} onChange={(v) => {
                                setFormInputs({...formInputs, captcha: v})
                            }}></Input>
                        </Form.Item>
                    </Layout.Col>
                </Layout.Row>
                    
                    */
                }
                
                <Layout.Row>
                    <Layout.Col xs="24" sm="24" md="12" lg="12">
                        <Form.Item prop="termsAndConditions">
                            <Checkbox.Group value={formInputs.termsAndConditions} onChange={(v) => {
                                setFormInputs({...formInputs, termsAndConditions: v})
                            }}>
                                <Checkbox name="termsAndConditions">
                                    
                                        <label>{t('register-form.agreement-checkbox-1')}
                                           <Link to="/privacy-policy" target="_blank">{t('footer.privacy-policy')}</Link> <span>and </span>
                                             <Link to="/terms-of-use" target="_blank">{t('footer.terms-of-use')}</Link>
                                        </label>
                                    
                                        <PrivacyPolicy language={language}
                                                       isPrivacyPolicyVisible={isPrivacyPolicyVisible}
                                                       setIsPrivacyPolicyVisible={setIsPrivacyPolicyVisible}/>

                                </Checkbox>
                                
                            </Checkbox.Group>
                        </Form.Item>
                    </Layout.Col>                
                </Layout.Row>

                <Layout.Row className="hidden-md-and-up isolated-row align-center" type="flex" justify="center" align="middle">
                    <Form.Item align="middle" justify="center">
                    <Layout.Col>
                        <Button onClick={handleObSubmit} type="primary" loading={loading}
                                className="primary-button mb-3">
                            {t('Create Account (Crear cuenta)')}
                        </Button>
                        </Layout.Col>
                        <Layout.Col>
                        <Link to="/" className="secondary-button">
                            {t('button-cancel')}
                        </Link> 
                        </Layout.Col>
                    </Form.Item>
                </Layout.Row>


                <Layout.Row className="hidden-sm-and-down isolated-row align-center" type="flex" justify="center" align="middle">
                    <Form.Item align="middle" justify="center">
                        <Link to="/" className="secondary-button">
                            {t('button-cancel')}
                        </Link>

                        <Button onClick={handleObSubmit} type="primary" loading={loading}
                                className="primary-button">
                            {t('Create Account (Crear cuenta)')}
                        </Button>
                    </Form.Item>
                </Layout.Row>

            </Form>
        </Layout.Row>
        </Layout.Col>

        <Layout.Col xs="24" sm="24" md="12" lg="12">
            <Layout.Row>
            <img width={'100%'} src={name}></img>
            </Layout.Row>
        </Layout.Col>
        </Layout.Row>
    </div>);
}

export default Register;