/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      description
      tags
      createdAt
      updatedAt
      
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      description
      tags
      createdAt
      updatedAt
      
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      description
      tags
      createdAt
      updatedAt
      
    }
  }
`;
export const createSubcategory = /* GraphQL */ `
  mutation CreateSubcategory(
    $input: CreateSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    createSubcategory(input: $input, condition: $condition) {
      id
      name
      description
      tags
      category_id
      category_name
      createdAt
      updatedAt
      products {
        nextToken
        
      }
      
    }
  }
`;
export const updateSubcategory = /* GraphQL */ `
  mutation UpdateSubcategory(
    $input: UpdateSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    updateSubcategory(input: $input, condition: $condition) {
      id
      name
      description
      tags
      category_id
      category_name
      createdAt
      updatedAt
      products {
        nextToken
        
      }
      
    }
  }
`;
export const deleteSubcategory = /* GraphQL */ `
  mutation DeleteSubcategory(
    $input: DeleteSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    deleteSubcategory(input: $input, condition: $condition) {
      id
      name
      description
      tags
      category_id
      category_name
      createdAt
      updatedAt
      products {
        nextToken
        
      }
      
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      owner
      owner_name
      alias_owner_name
      owner_last_message_read
      company_user
      company_user_name
      alias_company_user_name
      company_user_last_message_read
      company_name
      last_message
      buyer_phone
      seller_since
      buyer_since
      createdAt
      updatedAt
      
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      owner
      owner_name
      alias_owner_name
      owner_last_message_read
      company_user
      company_user_name
      alias_company_user_name
      company_user_last_message_read
      company_name
      last_message
      buyer_phone
      seller_since
      buyer_since
      createdAt
      updatedAt
      
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      owner
      owner_name
      alias_owner_name
      owner_last_message_read
      company_user
      company_user_name
      alias_company_user_name
      company_user_last_message_read
      company_name
      last_message
      buyer_phone
      seller_since
      buyer_since
      createdAt
      updatedAt
      
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      chat_id
      chat {
        id
        owner
        owner_name
        alias_owner_name
        owner_last_message_read
        company_user
        company_user_name
        alias_company_user_name
        company_user_last_message_read
        company_name
        last_message
        buyer_phone
        seller_since
        buyer_since
        createdAt
        updatedAt
        
      }
      from
      to
      message
      message_type
      file {
        bucket
        region
        key
        
      }
      product_id
      product_company_id
      product_company_name
      product_name
      product_image {
        bucket
        region
        key
        
      }
      createdAt
      updatedAt
      owner
      
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      chat_id
      chat {
        id
        owner
        owner_name
        alias_owner_name
        owner_last_message_read
        company_user
        company_user_name
        alias_company_user_name
        company_user_last_message_read
        company_name
        last_message
        buyer_phone
        seller_since
        buyer_since
        createdAt
        updatedAt
        
      }
      from
      to
      message
      message_type
      file {
        bucket
        region
        key
        
      }
      product_id
      product_company_id
      product_company_name
      product_name
      product_image {
        bucket
        region
        key
        
      }
      createdAt
      updatedAt
      owner
      
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      chat_id
      chat {
        id
        owner
        owner_name
        alias_owner_name
        owner_last_message_read
        company_user
        company_user_name
        alias_company_user_name
        company_user_last_message_read
        company_name
        last_message
        buyer_phone
        seller_since
        buyer_since
        createdAt
        updatedAt
        
      }
      from
      to
      message
      message_type
      file {
        bucket
        region
        key
        
      }
      product_id
      product_company_id
      product_company_name
      product_name
      product_image {
        bucket
        region
        key
        
      }
      createdAt
      updatedAt
      owner
      
    }
  }
`;
export const createCounterInfoTable = /* GraphQL */ `
  mutation CreateCounterInfoTable(
    $input: CreateCounterInfoTableInput!
    $condition: ModelCounterInfoTableConditionInput
  ) {
    createCounterInfoTable(input: $input, condition: $condition) {
      id
      owner
      products_total
      active_orders_total
      completed_orders_total
      chats_total
      extra1_total
      extra2_total
      extra3_total
      extra4_total
      extra5_total
      createdAt
      updatedAt
      
    }
  }
`;
export const updateCounterInfoTable = /* GraphQL */ `
  mutation UpdateCounterInfoTable(
    $input: UpdateCounterInfoTableInput!
    $condition: ModelCounterInfoTableConditionInput
  ) {
    updateCounterInfoTable(input: $input, condition: $condition) {
      id
      owner
      products_total
      active_orders_total
      completed_orders_total
      chats_total
      extra1_total
      extra2_total
      extra3_total
      extra4_total
      extra5_total
      createdAt
      updatedAt
      
    }
  }
`;
export const deleteCounterInfoTable = /* GraphQL */ `
  mutation DeleteCounterInfoTable(
    $input: DeleteCounterInfoTableInput!
    $condition: ModelCounterInfoTableConditionInput
  ) {
    deleteCounterInfoTable(input: $input, condition: $condition) {
      id
      owner
      products_total
      active_orders_total
      completed_orders_total
      chats_total
      extra1_total
      extra2_total
      extra3_total
      extra4_total
      extra5_total
      createdAt
      updatedAt
      
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      description
      big_description
      photo {
        bucket
        region
        key
        
      }
      file {
        bucket
        region
        key
        
      }
      addtional_photo {
        bucket
        region
        key
        
      }
      min_price
      max_price
      measurement
      min_order
      package_type
      customizable
      package_custom
      monthly_capacity
      client_service
      quantity_per_package
      package_dimensions
      package_weight
      warranty
      warranty_time
      tags
      owner
      brand
      int_ext
      size
      special_functions
      color
      material
      titles
      voltage
      power
      protection
      life_time
      createdAt
      status
      titles2
      search_field
      featured
      company_name
      company_id
      category_id
      subcategory_id
      subcategory_name
      key
      company_country
      verified
      bainub_account_year
      supplier_rating
      updatedAt
      category {
        id
        name
        description
        tags
        category_id
        category_name
        createdAt
        updatedAt
        
      }
      views
      
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      description
      big_description
      photo {
        bucket
        region
        key
        
      }
      file {
        bucket
        region
        key
        
      }
      addtional_photo {
        bucket
        region
        key
        
      }
      min_price
      max_price
      measurement
      min_order
      package_type
      customizable
      package_custom
      monthly_capacity
      client_service
      quantity_per_package
      package_dimensions
      package_weight
      warranty
      warranty_time
      tags
      owner
      brand
      int_ext
      size
      special_functions
      color
      material
      titles
      voltage
      power
      protection
      life_time
      createdAt
      status
      titles2
      search_field
      featured
      company_name
      company_id
      category_id
      subcategory_id
      subcategory_name
      key
      company_country
      verified
      bainub_account_year
      supplier_rating
      updatedAt
      category {
        id
        name
        description
        tags
        category_id
        category_name
        createdAt
        updatedAt
        
      }
      views
      
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      company_name
      first_name
      last_name
      user_type
      phone_number
      username
      email
      city
      country
      contact_type
      address_line1
      address_state
      address_zip
      cognito_id
      ref_code
      owner
      orders {
        nextToken
        
      }
      createdAt
      updatedAt
      
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      company_name
      first_name
      last_name
      user_type
      phone_number
      username
      email
      city
      country
      contact_type
      address_line1
      address_state
      address_zip
      cognito_id
      ref_code
      owner
      orders {
        nextToken
        
      }
      createdAt
      updatedAt
      
    }
  }
`;
export const createUserFavorites = /* GraphQL */ `
  mutation CreateUserFavorites(
    $input: CreateUserFavoritesInput!
    $condition: ModelUserFavoritesConditionInput
  ) {
    createUserFavorites(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      product {
        id
        name
        description
        big_description
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        views
        
      }
      
    }
  }
`;
export const updateUserFavorites = /* GraphQL */ `
  mutation UpdateUserFavorites(
    $input: UpdateUserFavoritesInput!
    $condition: ModelUserFavoritesConditionInput
  ) {
    updateUserFavorites(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      product {
        id
        name
        description
        big_description
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        views
        
      }
      
    }
  }
`;
export const deleteUserFavorites = /* GraphQL */ `
  mutation DeleteUserFavorites(
    $input: DeleteUserFavoritesInput!
    $condition: ModelUserFavoritesConditionInput
  ) {
    deleteUserFavorites(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      product {
        id
        name
        description
        big_description
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        views
        
      }
      
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      type
      owner
      age
      established_years
      anual_revenue
      description
      region
      featured_category
      cover_picture {
        bucket
        region
        key
        
      }
      company_logo {
        bucket
        region
        key
        
      }
      titles
      employees_qty
      production_lines_qty
      main_market
      additional_titles1
      additional_titles2
      additional_titles3
      additional_titles4
      allow_custom_product
      slogan
      address_str
      address {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      bainub_account_year
      verified
      supplier_rating
      hidden
      createdAt
      updatedAt
      
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      type
      owner
      age
      established_years
      anual_revenue
      description
      region
      featured_category
      cover_picture {
        bucket
        region
        key
        
      }
      company_logo {
        bucket
        region
        key
        
      }
      titles
      employees_qty
      production_lines_qty
      main_market
      additional_titles1
      additional_titles2
      additional_titles3
      additional_titles4
      allow_custom_product
      slogan
      address_str
      address {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      bainub_account_year
      verified
      supplier_rating
      hidden
      createdAt
      updatedAt
      
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      type
      owner
      age
      established_years
      anual_revenue
      description
      region
      featured_category
      cover_picture {
        bucket
        region
        key
        
      }
      company_logo {
        bucket
        region
        key
        
      }
      titles
      employees_qty
      production_lines_qty
      main_market
      additional_titles1
      additional_titles2
      additional_titles3
      additional_titles4
      allow_custom_product
      slogan
      address_str
      address {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      bainub_account_year
      verified
      supplier_rating
      hidden
      createdAt
      updatedAt
      
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      user {
        id
        company_name
        first_name
        last_name
        user_type
        phone_number
        username
        email
        city
        country
        contact_type
        address_line1
        address_state
        address_zip
        cognito_id
        ref_code
        owner
        createdAt
        updatedAt
        
      }
      createdAt
      updatedAt
      owner
      owner_name
      buyer
      buyer_name
      buyer_phone
      buyer_email
      products {
        name
        id
        quantity
        unit
        unitPrice
        subtotalPrice
        comments
        
      }
      orderNumber
      company_name
      company_id
      company_contact_name
      company_email
      company_address
      billingAddress {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      terms {
        currency
        paymentMethod
        deliveryDate
        guarantee
        incoterm
        
      }
      shipping {
        method
        cost
        status
        specialInstructions
        
      }
      additionalFees {
        fee
        quantity
        unit
        unitPrice
        subtotal
        description
        
      }
      seller_status
      buyer_status
      history {
        seller_status
        buyer_status
        process_status
        type
        date
        note
        
      }
      total
      payments {
        date
        amount
        currentBalance
        order
        method
        notes
        
      }
      tracking_number
      product {
        id
        name
        description
        big_description
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        views
        
      }
      
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      user {
        id
        company_name
        first_name
        last_name
        user_type
        phone_number
        username
        email
        city
        country
        contact_type
        address_line1
        address_state
        address_zip
        cognito_id
        ref_code
        owner
        createdAt
        updatedAt
        
      }
      createdAt
      updatedAt
      owner
      owner_name
      buyer
      buyer_name
      buyer_phone
      buyer_email
      products {
        name
        id
        quantity
        unit
        unitPrice
        subtotalPrice
        comments
        
      }
      orderNumber
      company_name
      company_id
      company_contact_name
      company_email
      company_address
      billingAddress {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      terms {
        currency
        paymentMethod
        deliveryDate
        guarantee
        incoterm
        
      }
      shipping {
        method
        cost
        status
        specialInstructions
        
      }
      additionalFees {
        fee
        quantity
        unit
        unitPrice
        subtotal
        description
        
      }
      seller_status
      buyer_status
      history {
        seller_status
        buyer_status
        process_status
        type
        date
        note
        
      }
      total
      payments {
        date
        amount
        currentBalance
        order
        method
        notes
        
      }
      tracking_number
      product {
        id
        name
        description
        big_description
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        views
        
      }
      
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      user {
        id
        company_name
        first_name
        last_name
        user_type
        phone_number
        username
        email
        city
        country
        contact_type
        address_line1
        address_state
        address_zip
        cognito_id
        ref_code
        owner
        createdAt
        updatedAt
        
      }
      createdAt
      updatedAt
      owner
      owner_name
      buyer
      buyer_name
      buyer_phone
      buyer_email
      products {
        name
        id
        quantity
        unit
        unitPrice
        subtotalPrice
        comments
        
      }
      orderNumber
      company_name
      company_id
      company_contact_name
      company_email
      company_address
      billingAddress {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      terms {
        currency
        paymentMethod
        deliveryDate
        guarantee
        incoterm
        
      }
      shipping {
        method
        cost
        status
        specialInstructions
        
      }
      additionalFees {
        fee
        quantity
        unit
        unitPrice
        subtotal
        description
        
      }
      seller_status
      buyer_status
      history {
        seller_status
        buyer_status
        process_status
        type
        date
        note
        
      }
      total
      payments {
        date
        amount
        currentBalance
        order
        method
        notes
        
      }
      tracking_number
      product {
        id
        name
        description
        big_description
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        views
        
      }
      
    }
  }
`;
export const createBusinessPreference = /* GraphQL */ `
  mutation CreateBusinessPreference(
    $input: CreateBusinessPreferenceInput!
    $condition: ModelBusinessPreferenceConditionInput
  ) {
    createBusinessPreference(input: $input, condition: $condition) {
      id
      business_preferences {
        category
        subcategories
        
      }
      business_definition
      owner
      createdAt
      updatedAt
      
    }
  }
`;
export const updateBusinessPreference = /* GraphQL */ `
  mutation UpdateBusinessPreference(
    $input: UpdateBusinessPreferenceInput!
    $condition: ModelBusinessPreferenceConditionInput
  ) {
    updateBusinessPreference(input: $input, condition: $condition) {
      id
      business_preferences {
        category
        subcategories
        
      }
      business_definition
      owner
      createdAt
      updatedAt
      
    }
  }
`;
export const deleteBusinessPreference = /* GraphQL */ `
  mutation DeleteBusinessPreference(
    $input: DeleteBusinessPreferenceInput!
    $condition: ModelBusinessPreferenceConditionInput
  ) {
    deleteBusinessPreference(input: $input, condition: $condition) {
      id
      business_preferences {
        category
        subcategories
        
      }
      business_definition
      owner
      createdAt
      updatedAt
      
    }
  }
`;
export const createSubcategoryFormField = /* GraphQL */ `
  mutation CreateSubcategoryFormField(
    $input: CreateSubcategoryFormFieldInput!
    $condition: ModelSubcategoryFormFieldConditionInput
  ) {
    createSubcategoryFormField(input: $input, condition: $condition) {
      id
      subcategory_id
      form_fields
      createdAt
      updatedAt
      
    }
  }
`;
export const updateSubcategoryFormField = /* GraphQL */ `
  mutation UpdateSubcategoryFormField(
    $input: UpdateSubcategoryFormFieldInput!
    $condition: ModelSubcategoryFormFieldConditionInput
  ) {
    updateSubcategoryFormField(input: $input, condition: $condition) {
      id
      subcategory_id
      form_fields
      createdAt
      updatedAt
      
    }
  }
`;
export const deleteSubcategoryFormField = /* GraphQL */ `
  mutation DeleteSubcategoryFormField(
    $input: DeleteSubcategoryFormFieldInput!
    $condition: ModelSubcategoryFormFieldConditionInput
  ) {
    deleteSubcategoryFormField(input: $input, condition: $condition) {
      id
      subcategory_id
      form_fields
      createdAt
      updatedAt
      
    }
  }
`;
export const deleteContactUsFormFill = /* GraphQL */ `
  mutation DeleteContactUsFormFill(
    $input: DeleteContactUsFormFillInput!
    $condition: ModelContactUsFormFillConditionInput
  ) {
    deleteContactUsFormFill(input: $input, condition: $condition) {
      id
      contact_reason
      full_name
      email
      business_name
      phone
      message
      is_bainub_user
      contacted_by
      contacted_at
      createdAt
      updatedAt
      
    }
  }
`;
export const createUnsuscribedEmails = /* GraphQL */ `
  mutation CreateUnsuscribedEmails(
    $input: CreateUnsuscribedEmailsInput!
    $condition: ModelunsuscribedEmailsConditionInput
  ) {
    createUnsuscribedEmails(input: $input, condition: $condition) {
      id
      owner
      email
      createdAt
      updatedAt
      
    }
  }
`;
export const updateUnsuscribedEmails = /* GraphQL */ `
  mutation UpdateUnsuscribedEmails(
    $input: UpdateUnsuscribedEmailsInput!
    $condition: ModelunsuscribedEmailsConditionInput
  ) {
    updateUnsuscribedEmails(input: $input, condition: $condition) {
      id
      owner
      email
      createdAt
      updatedAt
      
    }
  }
`;
export const deleteUnsuscribedEmails = /* GraphQL */ `
  mutation DeleteUnsuscribedEmails(
    $input: DeleteUnsuscribedEmailsInput!
    $condition: ModelunsuscribedEmailsConditionInput
  ) {
    deleteUnsuscribedEmails(input: $input, condition: $condition) {
      id
      owner
      email
      createdAt
      updatedAt
      
    }
  }
`;
export const createUserNotificationSent = /* GraphQL */ `
  mutation CreateUserNotificationSent(
    $input: CreateUserNotificationSentInput!
    $condition: ModeluserNotificationSentConditionInput
  ) {
    createUserNotificationSent(input: $input, condition: $condition) {
      id
      email
      count
      createdAt
      updatedAt
      
    }
  }
`;
export const updateUserNotificationSent = /* GraphQL */ `
  mutation UpdateUserNotificationSent(
    $input: UpdateUserNotificationSentInput!
    $condition: ModeluserNotificationSentConditionInput
  ) {
    updateUserNotificationSent(input: $input, condition: $condition) {
      id
      email
      count
      createdAt
      updatedAt
      
    }
  }
`;
export const deleteUserNotificationSent = /* GraphQL */ `
  mutation DeleteUserNotificationSent(
    $input: DeleteUserNotificationSentInput!
    $condition: ModeluserNotificationSentConditionInput
  ) {
    deleteUserNotificationSent(input: $input, condition: $condition) {
      id
      email
      count
      createdAt
      updatedAt
      
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      description
      big_description
      photo {
        bucket
        region
        key
        
      }
      file {
        bucket
        region
        key
        
      }
      addtional_photo {
        bucket
        region
        key
        
      }
      min_price
      max_price
      measurement
      min_order
      package_type
      customizable
      package_custom
      monthly_capacity
      client_service
      quantity_per_package
      package_dimensions
      package_weight
      warranty
      warranty_time
      tags
      owner
      brand
      int_ext
      size
      special_functions
      color
      material
      titles
      voltage
      power
      protection
      life_time
      createdAt
      status
      titles2
      search_field
      featured
      company_name
      company_id
      category_id
      subcategory_id
      subcategory_name
      key
      company_country
      verified
      bainub_account_year
      supplier_rating
      updatedAt
      category {
        id
        name
        description
        tags
        category_id
        category_name
        createdAt
        updatedAt
        
      }
      views
      
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      company_name
      first_name
      last_name
      user_type
      phone_number
      username
      email
      city
      country
      contact_type
      address_line1
      address_state
      address_zip
      cognito_id
      ref_code
      owner
      orders {
        nextToken
        
      }
      createdAt
      updatedAt
      
    }
  }
`;
export const createContactUsFormFill = /* GraphQL */ `
  mutation CreateContactUsFormFill(
    $input: CreateContactUsFormFillInput!
    $condition: ModelContactUsFormFillConditionInput
  ) {
    createContactUsFormFill(input: $input, condition: $condition) {
      id
      contact_reason
      full_name
      email
      business_name
      phone
      message
      is_bainub_user
      contacted_by
      contacted_at
      createdAt
      updatedAt
      
    }
  }
`;
export const updateContactUsFormFill = /* GraphQL */ `
  mutation UpdateContactUsFormFill(
    $input: UpdateContactUsFormFillInput!
    $condition: ModelContactUsFormFillConditionInput
  ) {
    updateContactUsFormFill(input: $input, condition: $condition) {
      id
      contact_reason
      full_name
      email
      business_name
      phone
      message
      is_bainub_user
      contacted_by
      contacted_at
      createdAt
      updatedAt
      
    }
  }
`;
