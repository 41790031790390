import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

function SearchResultRedirect() {

  let history = useHistory();

    useEffect(() => {
        history.push('/search-result-ng/$EMPTY$/all%7Call?query=$EMPTY$');
    },[])

  return (
    <div>
    </div>
  );
}

export default SearchResultRedirect;