/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      owner
      owner_name
      alias_owner_name
      owner_last_message_read
      company_user
      company_user_name
      alias_company_user_name
      company_user_last_message_read
      company_name
      last_message
      buyer_phone
      seller_since
      buyer_since
      createdAt
      updatedAt
      
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        owner_name
        alias_owner_name
        owner_last_message_read
        company_user
        company_user_name
        alias_company_user_name
        company_user_last_message_read
        company_name
        last_message
        buyer_phone
        seller_since
        buyer_since
        createdAt
        updatedAt
        
      }
      nextToken
      
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      chat_id
      chat {
        id
        owner
        owner_name
        alias_owner_name
        owner_last_message_read
        company_user
        company_user_name
        alias_company_user_name
        company_user_last_message_read
        company_name
        last_message
        buyer_phone
        seller_since
        buyer_since
        createdAt
        updatedAt
        
      }
      from
      to
      message
      message_type
      file {
        bucket
        region
        key
        
      }
      product_id
      product_company_id
      product_company_name
      product_name
      product_image {
        bucket
        region
        key
        
      }
      createdAt
      updatedAt
      owner
      
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chat_id
        chat {
          id
          owner
          owner_name
          alias_owner_name
          owner_last_message_read
          company_user
          company_user_name
          alias_company_user_name
          company_user_last_message_read
          company_name
          last_message
          buyer_phone
          seller_since
          buyer_since
          createdAt
          updatedAt
          
        }
        from
        to
        message
        message_type
        file {
          bucket
          region
          key
          
        }
        product_id
        product_company_id
        product_company_name
        product_name
        product_image {
          bucket
          region
          key
          
        }
        createdAt
        updatedAt
        owner
        
      }
      nextToken
      
    }
  }
`;
export const getCounterInfoTable = /* GraphQL */ `
  query GetCounterInfoTable($id: ID!) {
    getCounterInfoTable(id: $id) {
      id
      owner
      products_total
      active_orders_total
      completed_orders_total
      chats_total
      extra1_total
      extra2_total
      extra3_total
      extra4_total
      extra5_total
      createdAt
      updatedAt
      
    }
  }
`;
export const listCounterInfoTables = /* GraphQL */ `
  query ListCounterInfoTables(
    $filter: ModelCounterInfoTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCounterInfoTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        products_total
        active_orders_total
        completed_orders_total
        chats_total
        extra1_total
        extra2_total
        extra3_total
        extra4_total
        extra5_total
        createdAt
        updatedAt
        
      }
      nextToken
      
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      company_name
      first_name
      last_name
      user_type
      phone_number
      username
      email
      city
      country
      contact_type
      address_line1
      address_state
      address_zip
      cognito_id
      ref_code
      owner
      orders {
        items {
          id
          createdAt
          updatedAt
          owner
          owner_name
          buyer
          buyer_name
          buyer_phone
          buyer_email
          orderNumber
          company_name
          company_id
          company_contact_name
          company_email
          company_address
          seller_status
          buyer_status
          total
          tracking_number
          
        }
        nextToken
        
      }
      createdAt
      updatedAt
      
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        first_name
        last_name
        user_type
        phone_number
        username
        email
        city
        country
        contact_type
        address_line1
        address_state
        address_zip
        cognito_id
        ref_code
        owner
        orders {
          nextToken
          
        }
        createdAt
        updatedAt
        
      }
      nextToken
      
    }
  }
`;
export const getUserFavorites = /* GraphQL */ `
  query GetUserFavorites($id: ID!) {
    getUserFavorites(id: $id) {
      id
      owner
      createdAt
      updatedAt
      product {
        id
        name
        description
        big_description
        photo {
          bucket
          region
          key
          
        }
        file {
          bucket
          region
          key
          
        }
        addtional_photo {
          bucket
          region
          key
          
        }
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        category {
          id
          name
          description
          tags
          category_id
          category_name
          createdAt
          updatedAt
          
        }
        views
        
      }
      
    }
  }
`;
export const listUserFavoritess = /* GraphQL */ `
  query ListUserFavoritess(
    $filter: ModelUserFavoritesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFavoritess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        createdAt
        updatedAt
        product {
          id
          name
          description
          big_description
          min_price
          max_price
          measurement
          min_order
          package_type
          customizable
          package_custom
          monthly_capacity
          client_service
          quantity_per_package
          package_dimensions
          package_weight
          warranty
          warranty_time
          tags
          owner
          brand
          int_ext
          size
          special_functions
          color
          material
          titles
          voltage
          power
          protection
          life_time
          createdAt
          status
          titles2
          search_field
          featured
          company_name
          company_id
          category_id
          subcategory_id
          subcategory_name
          key
          company_country
          verified
          bainub_account_year
          supplier_rating
          updatedAt
          views
          
        }
        
      }
      nextToken
      
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      user {
        id
        company_name
        first_name
        last_name
        user_type
        phone_number
        username
        email
        city
        country
        contact_type
        address_line1
        address_state
        address_zip
        cognito_id
        ref_code
        owner
        orders {
          nextToken
          
        }
        createdAt
        updatedAt
        
      }
      createdAt
      updatedAt
      owner
      owner_name
      buyer
      buyer_name
      buyer_phone
      buyer_email
      products {
        name
        id
        quantity
        unit
        unitPrice
        subtotalPrice
        comments
        photo {
          bucket
          region
          key
          
        }
        
      }
      orderNumber
      company_name
      company_id
      company_contact_name
      company_email
      company_address
      billingAddress {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      terms {
        currency
        paymentMethod
        deliveryDate
        guarantee
        incoterm
        
      }
      shipping {
        method
        cost
        status
        originAddress {
          street
          city
          state
          country
          zipCode
          phone
          contact
          
        }
        destinationAddress {
          street
          city
          state
          country
          zipCode
          phone
          contact
          
        }
        specialInstructions
        
      }
      additionalFees {
        fee
        quantity
        unit
        unitPrice
        subtotal
        description
        
      }
      seller_status
      buyer_status
      history {
        seller_status
        buyer_status
        process_status
        type
        date
        note
        
      }
      total
      payments {
        date
        amount
        currentBalance
        order
        method
        notes
        
      }
      tracking_number
      product {
        id
        name
        description
        big_description
        photo {
          bucket
          region
          key
          
        }
        file {
          bucket
          region
          key
          
        }
        addtional_photo {
          bucket
          region
          key
          
        }
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        category {
          id
          name
          description
          tags
          category_id
          category_name
          createdAt
          updatedAt
          
        }
        views
        
      }
      
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          company_name
          first_name
          last_name
          user_type
          phone_number
          username
          email
          city
          country
          contact_type
          address_line1
          address_state
          address_zip
          cognito_id
          ref_code
          owner
          createdAt
          updatedAt
          
        }
        createdAt
        updatedAt
        owner
        owner_name
        buyer
        buyer_name
        buyer_phone
        buyer_email
        products {
          name
          id
          quantity
          unit
          unitPrice
          subtotalPrice
          comments
          
        }
        orderNumber
        company_name
        company_id
        company_contact_name
        company_email
        company_address
        billingAddress {
          street
          city
          state
          country
          zipCode
          phone
          contact
          
        }
        terms {
          currency
          paymentMethod
          deliveryDate
          guarantee
          incoterm
          
        }
        shipping {
          method
          cost
          status
          specialInstructions
          
        }
        additionalFees {
          fee
          quantity
          unit
          unitPrice
          subtotal
          description
          
        }
        seller_status
        buyer_status
        history {
          seller_status
          buyer_status
          process_status
          type
          date
          note
          
        }
        total
        payments {
          date
          amount
          currentBalance
          order
          method
          notes
          
        }
        tracking_number
        product {
          id
          name
          description
          big_description
          min_price
          max_price
          measurement
          min_order
          package_type
          customizable
          package_custom
          monthly_capacity
          client_service
          quantity_per_package
          package_dimensions
          package_weight
          warranty
          warranty_time
          tags
          owner
          brand
          int_ext
          size
          special_functions
          color
          material
          titles
          voltage
          power
          protection
          life_time
          createdAt
          status
          titles2
          search_field
          featured
          company_name
          company_id
          category_id
          subcategory_id
          subcategory_name
          key
          company_country
          verified
          bainub_account_year
          supplier_rating
          updatedAt
          views
          
        }
        
      }
      nextToken
      
    }
  }
`;
export const messagesByDate = /* GraphQL */ `
  query MessagesByDate(
    $chat_id: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByDate(
      chat_id: $chat_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chat_id
        chat {
          id
          owner
          owner_name
          alias_owner_name
          owner_last_message_read
          company_user
          company_user_name
          alias_company_user_name
          company_user_last_message_read
          company_name
          last_message
          buyer_phone
          seller_since
          buyer_since
          createdAt
          updatedAt
          
        }
        from
        to
        message
        message_type
        file {
          bucket
          region
          key
          
        }
        product_id
        product_company_id
        product_company_name
        product_name
        product_image {
          bucket
          region
          key
          
        }
        createdAt
        updatedAt
        owner
        
      }
      nextToken
      
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      description
      tags
      createdAt
      updatedAt
      
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        tags
        createdAt
        updatedAt
        
      }
      nextToken
      
    }
  }
`;
export const getSubcategory = /* GraphQL */ `
  query GetSubcategory($id: ID!) {
    getSubcategory(id: $id) {
      id
      name
      description
      tags
      category_id
      category_name
      createdAt
      updatedAt
      products {
        items {
          id
          name
          description
          big_description
          min_price
          max_price
          measurement
          min_order
          package_type
          customizable
          package_custom
          monthly_capacity
          client_service
          quantity_per_package
          package_dimensions
          package_weight
          warranty
          warranty_time
          tags
          owner
          brand
          int_ext
          size
          special_functions
          color
          material
          titles
          voltage
          power
          protection
          life_time
          createdAt
          status
          titles2
          search_field
          featured
          company_name
          company_id
          category_id
          subcategory_id
          subcategory_name
          key
          company_country
          verified
          bainub_account_year
          supplier_rating
          updatedAt
          views
          
        }
        nextToken
        
      }
      
    }
  }
`;
export const listSubcategorys = /* GraphQL */ `
  query ListSubcategorys(
    $filter: ModelSubcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubcategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        tags
        category_id
        category_name
        createdAt
        updatedAt
        products {
          nextToken
          
        }
        
      }
      nextToken
      
    }
  }
`;
export const listProductsOrderByMinPrice = /* GraphQL */ `
  query ListProductsOrderByMinPrice(
    $key: String
    $min_price: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsOrderByMinPrice(
      key: $key
      min_price: $min_price
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        big_description
        photo {
          bucket
          region
          key
          
        }
        file {
          bucket
          region
          key
          
        }
        addtional_photo {
          bucket
          region
          key
          
        }
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        category {
          id
          name
          description
          tags
          category_id
          category_name
          createdAt
          updatedAt
          
        }
        views
        
      }
      nextToken
      
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      description
      big_description
      photo {
        bucket
        region
        key
        
      }
      file {
        bucket
        region
        key
        
      }
      addtional_photo {
        bucket
        region
        key
        
      }
      min_price
      max_price
      measurement
      min_order
      package_type
      customizable
      package_custom
      monthly_capacity
      client_service
      quantity_per_package
      package_dimensions
      package_weight
      warranty
      warranty_time
      tags
      owner
      brand
      int_ext
      size
      special_functions
      color
      material
      titles
      voltage
      power
      protection
      life_time
      createdAt
      status
      titles2
      search_field
      featured
      company_name
      company_id
      category_id
      subcategory_id
      subcategory_name
      key
      company_country
      verified
      bainub_account_year
      supplier_rating
      updatedAt
      category {
        id
        name
        description
        tags
        category_id
        category_name
        createdAt
        updatedAt
        products {
          nextToken
          
        }
        
      }
      views
      
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        big_description
        photo {
          bucket
          region
          key
          
        }
        file {
          bucket
          region
          key
          
        }
        addtional_photo {
          bucket
          region
          key
          
        }
        min_price
        max_price
        measurement
        min_order
        package_type
        customizable
        package_custom
        monthly_capacity
        client_service
        quantity_per_package
        package_dimensions
        package_weight
        warranty
        warranty_time
        tags
        owner
        brand
        int_ext
        size
        special_functions
        color
        material
        titles
        voltage
        power
        protection
        life_time
        createdAt
        status
        titles2
        search_field
        featured
        company_name
        company_id
        category_id
        subcategory_id
        subcategory_name
        key
        company_country
        verified
        bainub_account_year
        supplier_rating
        updatedAt
        category {
          id
          name
          description
          tags
          category_id
          category_name
          createdAt
          updatedAt
          
        }
        views
        
      }
      nextToken
      
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      type
      owner
      age
      established_years
      anual_revenue
      description
      region
      featured_category
      cover_picture {
        bucket
        region
        key
        
      }
      company_logo {
        bucket
        region
        key
        
      }
      titles
      employees_qty
      production_lines_qty
      main_market
      additional_titles1
      additional_titles2
      additional_titles3
      additional_titles4
      allow_custom_product
      slogan
      address_str
      address {
        street
        city
        state
        country
        zipCode
        phone
        contact
        
      }
      bainub_account_year
      verified
      supplier_rating
      hidden
      createdAt
      updatedAt
      
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        owner
        age
        established_years
        anual_revenue
        description
        region
        featured_category
        cover_picture {
          bucket
          region
          key
          
        }
        company_logo {
          bucket
          region
          key
          
        }
        titles
        employees_qty
        production_lines_qty
        main_market
        additional_titles1
        additional_titles2
        additional_titles3
        additional_titles4
        allow_custom_product
        slogan
        address_str
        address {
          street
          city
          state
          country
          zipCode
          phone
          contact
          
        }
        bainub_account_year
        verified
        supplier_rating
        hidden
        createdAt
        updatedAt
        
      }
      nextToken
      
    }
  }
`;
export const getBusinessPreference = /* GraphQL */ `
  query GetBusinessPreference($id: ID!) {
    getBusinessPreference(id: $id) {
      id
      business_preferences {
        category
        subcategories
        
      }
      business_definition
      owner
      createdAt
      updatedAt
      
    }
  }
`;
export const listBusinessPreferences = /* GraphQL */ `
  query ListBusinessPreferences(
    $filter: ModelBusinessPreferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessPreferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        business_preferences {
          category
          subcategories
          
        }
        business_definition
        owner
        createdAt
        updatedAt
        
      }
      nextToken
      
    }
  }
`;
export const getSubcategoryFormField = /* GraphQL */ `
  query GetSubcategoryFormField($id: ID!) {
    getSubcategoryFormField(id: $id) {
      id
      subcategory_id
      form_fields
      createdAt
      updatedAt
      
    }
  }
`;
export const listSubcategoryFormFields = /* GraphQL */ `
  query ListSubcategoryFormFields(
    $filter: ModelSubcategoryFormFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubcategoryFormFields(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subcategory_id
        form_fields
        createdAt
        updatedAt
        
      }
      nextToken
      
    }
  }
`;
export const getContactUsFormFill = /* GraphQL */ `
  query GetContactUsFormFill($id: ID!) {
    getContactUsFormFill(id: $id) {
      id
      contact_reason
      full_name
      email
      business_name
      phone
      message
      is_bainub_user
      contacted_by
      contacted_at
      createdAt
      updatedAt
      
    }
  }
`;
export const listContactUsFormFills = /* GraphQL */ `
  query ListContactUsFormFills(
    $filter: ModelContactUsFormFillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactUsFormFills(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contact_reason
        full_name
        email
        business_name
        phone
        message
        is_bainub_user
        contacted_by
        contacted_at
        createdAt
        updatedAt
        
      }
      nextToken
      
    }
  }
`;
export const getUnsuscribedEmails = /* GraphQL */ `
  query GetUnsuscribedEmails($id: ID!) {
    getUnsuscribedEmails(id: $id) {
      id
      owner
      email
      createdAt
      updatedAt
      
    }
  }
`;
export const listUnsuscribedEmailss = /* GraphQL */ `
  query ListUnsuscribedEmailss(
    $filter: ModelunsuscribedEmailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnsuscribedEmailss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        email
        createdAt
        updatedAt
        
      }
      nextToken
      
    }
  }
`;
export const getUserNotificationSent = /* GraphQL */ `
  query GetUserNotificationSent($id: ID!) {
    getUserNotificationSent(id: $id) {
      id
      email
      count
      createdAt
      updatedAt
      
    }
  }
`;
export const listUserNotificationSents = /* GraphQL */ `
  query ListUserNotificationSents(
    $filter: ModeluserNotificationSentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNotificationSents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        count
        createdAt
        updatedAt
        
      }
      nextToken
      
    }
  }
`;
